import axios, { AxiosError } from 'axios'
import { AddMessage } from '../../redux/reducers/ChatBotReducer'
import { Dispatch } from 'redux'
import { getKeyChatBot } from '../../redux/actions/authentication'

// const id = '1680905287055x920094037663809500'
// const api_key = 'gmyIXCHqKJlzkngCfiClExPSxoexsj'

interface IPropsChatBot {
  setSuggestedQuestions: (cb: string[]) => void
  value: string
  setLoading: (cb: boolean) => void
  dispatch: Dispatch
  setStatus: (cb: number) => void
}
export const sendMessages = async ({ setLoading, value, setSuggestedQuestions, dispatch, setStatus }: IPropsChatBot) => {
  setLoading(true)
  await axios
    .post('https://myaskai.com/api/1.1/wf/ask-ai-chat', {
      id: getKeyChatBot().AIAssistantId,
      api_key: getKeyChatBot().AIAssistantApiKey,
      messages: [{ role: 'user', content: value }],
    })
    .then(
      ({ data, status }) => {
        dispatch(AddMessage({ text: data.answer, role: 'bot' }))
        setSuggestedQuestions(data.suggestedQuestions ?? data.suggested_questions)
        setStatus(status)
      },
      (e: AxiosError) => {
        setStatus(e.request.status)
      },
    )

  setLoading(false)
}
