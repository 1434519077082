import React, { useCallback, useEffect, useState, ChangeEvent, RefObject, useRef, memo } from 'react'
import cls from './chatBot.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClose, faMessage, faPaperPlane, faReply, faUserAstronaut, faUserSecret } from '@fortawesome/free-solid-svg-icons'
import { useDispatch, useSelector } from 'react-redux'
import { AddMessage, ResetMessage } from '../../redux/reducers/ChatBotReducer'
import { withTooltipPreventOverflow } from '../ConfTable'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'
import { Spinner } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { sendMessages } from './servises'
import { getKeyChatBot } from '../../redux/actions/authentication'

export const ChatBot = memo(
  () => {
    const dispatch = useDispatch()
    const { Messages } = useSelector((state: any) => state.ChatBot)
    const scrollRef: RefObject<OverlayScrollbarsComponent> = useRef(null)
    const [open, setOpen] = useState<boolean>(false)
    const [value, setValue] = useState<string>('')
    const [suggestedQuestions, setSuggestedQuestions] = useState([''])
    const [loading, setLoading] = useState(false)
    const [status, setStatus] = useState(200)
    let content = null

    useEffect(() => {
      setSuggestedQuestions([
        'Is there a way to set up alerts for trading activity?',
        'How can I view the logs in real-time?',
        'How to connect the MT5 platform to Trade Processor?',
      ])
    }, [])

    useEffect(() => {
      if (scrollRef?.current) {
        scrollRef?.current.osInstance()?.scroll({ y: '100%' }, 300, 'easeOutQuint')
      }
    }, [Messages, loading, suggestedQuestions, open])

    useEffect(() => {
      window.addEventListener('keydown', onKeyPresEscape)
      return () => {
        window.removeEventListener('keydown', onKeyPresEscape)
      }
    }, [value])

    const onKeyPresEscape = useCallback((e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        setOpen(false)
        setValue('')
      }
    }, [])

    const onKeyPres = useCallback(
      (e: any) => {
        if (e.key === 'Enter' && value.length) {
          dispatch(AddMessage({ text: value, role: 'user' }))
          setValue('')
          setSuggestedQuestions([])

          sendMessages({ setLoading, value: escape(value), setSuggestedQuestions, dispatch, setStatus })
        }
      },
      [value],
    )

    const onOpen = useCallback(() => {
      setOpen(true)
    }, [])

    const onClose = useCallback(() => {
      setOpen(false)
      setValue('')
    }, [])

    const onChangeMsg = useCallback((e: ChangeEvent<HTMLInputElement>) => {
      setValue(e.target.value)
    }, [])

    const onSendMessage = useCallback(async () => {
      if (!value.length) {
        return
      }
      dispatch(AddMessage({ text: value, role: 'user' }))
      setValue('')
      setSuggestedQuestions([])

      sendMessages({ setLoading, value: escape(value), setSuggestedQuestions, dispatch, setStatus })
    }, [value])

    const onSendSuggestedQuestions = useCallback(async (e: any) => {
      const value: string = e.target.innerText
      dispatch(AddMessage({ text: value, role: 'user' }))
      setSuggestedQuestions([])

      sendMessages({ setLoading, value: escape(value), setSuggestedQuestions, dispatch, setStatus })
    }, [])

    const onReset = useCallback(() => {
      dispatch(ResetMessage())
      setValue('')
    }, [])

    if (!open) {
      content = (
        <>
          {withTooltipPreventOverflow(
            <div className={cls.wrapperClose} onClick={onOpen}>
              <FontAwesomeIcon icon={faMessage} size="lg" />
            </div>,
            `Assistant`,
            `Assistant`,
            '',
            'left',
          )}
        </>
      )
    } else {
      content = (
        <>
          <div className={cls.wrapperOpen}>
            <div className={cls.header}>
              <FormattedMessage id="t4bBot" />
              <div>
                {withTooltipPreventOverflow(
                  <button type="button" onClick={onReset} className={cls.btn}>
                    <FontAwesomeIcon icon={faReply} />
                  </button>,
                  `Start`,
                  `Start`,
                )}
                {withTooltipPreventOverflow(
                  <button type="button" onClick={onClose} className={cls.btn}>
                    <FontAwesomeIcon icon={faClose} />
                  </button>,
                  `Close`,
                  `Close`,
                )}
              </div>
            </div>
            <OverlayScrollbarsComponent ref={scrollRef} style={{ height: '500px', width: '100%' }}>
              <div className={cls.content}>
                {Messages.map((item: any) => {
                  return (
                    <div className={item.role === 'bot' ? cls.msgBot : cls.msgUser} key={item.text}>
                      <FontAwesomeIcon icon={item.role === 'bot' ? faUserSecret : faUserAstronaut} className={cls.svgIcon} />
                      <text>{`${item.text}`}</text>
                    </div>
                  )
                })}
                {(loading || (!suggestedQuestions?.length && status === 0)) && (
                  <div className={cls.msgBot}>
                    <FontAwesomeIcon icon={faUserSecret} className={cls.svgIcon} />
                    <Spinner animation="grow" variant="secondary" size="sm" />
                    <Spinner animation="grow" variant="secondary" size="sm" />
                    <Spinner animation="grow" variant="secondary" size="sm" />
                  </div>
                )}
                {status !== 200 && status > 0 && (
                  <div className={cls.msgBot}>
                    <FontAwesomeIcon icon={faUserSecret} className={cls.svgIcon} />
                    <text>AI chatbot is unavailable, contact TP support to fix it. </text>
                  </div>
                )}
              </div>
            </OverlayScrollbarsComponent>
            <div className={cls.suggestedQuestions}>
              {Messages?.length &&
                suggestedQuestions?.map((item: string) => {
                  return (
                    <button type="button" className={cls.msgButtonBot} key={item} onClick={onSendSuggestedQuestions}>
                      {item}
                    </button>
                  )
                })}
            </div>
          </div>
          <div className={cls.wrapperInput}>
            <input
              disabled={loading || status !== 200}
              onKeyPress={onKeyPres}
              type="text"
              placeholder="Ask me a question..."
              value={value}
              onChange={onChangeMsg}
              className={cls.textMsg}
            ></input>
            {withTooltipPreventOverflow(
              <button type="button" onClick={onSendMessage} className={cls.pushMsg}>
                <FontAwesomeIcon icon={faPaperPlane} />
              </button>,
              `SendMsg`,
              `SendMsg`,
              '',
              'left',
            )}
          </div>
        </>
      )
    }

    return !Boolean(getKeyChatBot()?.AIAssistantId) || !Boolean(getKeyChatBot()?.AIAssistantApiKey) ? <></> : content
  },
  () => true,
)
