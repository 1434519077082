import { blankInput, buildControlsExtTwoPerLine, checkboxInput, passwordInput, sselectInput, textInput, timeInput, timespanInput } from '../../../utils/controls'
import {
  binanceType,
  brandFieldSet,
  OkxLive,
  onBehalfOfCompIdFieldSet,
  partyIdFieldSet,
  queryIntervalFieldSet,
  quoteTextProcessingFieldSet,
  resubscribeOnRejectTimeoutFieldSet,
  saxoClientIdAccountNumber,
  senderSubIdFieldSet,
  senderSubIdFieldSetFeedExec,
  targetBinanceFuturesFieldSet,
  targetSubIDFieldSet,
} from './lpType'
import { buildMultiselectOptionsFromArray } from '../../../utils/multiselect-utils'
export const executionSession = (
  execState: any,
  setExecState: any,
  execTouched: any,
  setExecTouched: any,
  execErrors: any,
  type: string,
  inputState: any,
  gateway: any,
  sendercompTargetcompId: any,
) => {
  const alreadyExist = () => {
    for (const item of sendercompTargetcompId) {
      if (
        item?.SenderCompId === execState.SenderCompId &&
        item?.TargetCompId === execState.TargetCompId &&
        execState.SenderCompId.length !== 0 &&
        execState.TargetCompId.length !== 0
      ) {
        execErrors.SenderCompId = true
        execErrors.TargetCompId = true
        return 'Аlready exists'
      }
    }

    if (execState.SenderCompId === execState.TargetCompId && execState.SenderCompId.length !== 0 && execState.TargetCompId.length !== 0) {
      execErrors.SenderCompId = true
      execErrors.TargetCompId = true
      return 'Incorrect value'
    }
    return ''
  }

  return buildControlsExtTwoPerLine(
    [
      textInput('SenderCompId').errorMessage(alreadyExist()),
      textInput('TargetCompId').errorMessage(alreadyExist()),
      textInput('BeginString'),
      textInput('Username'),
      textInput('Password'),
      textInput('ReconnectInterval'),
      textInput('FileStorePath'),
      textInput('FileLogPath'),
      checkboxInput('WriteLogs'),
      textInput('SocketConnectHost'),
      textInput('SocketConnectPort'),
      textInput('MaxMessagesPerSec'),
      textInput('Account'),
      textInput('AccountId').skipWhen(!saxoClientIdAccountNumber.has(inputState.Type.value)),
      textInput('ClientId').skipWhen(!saxoClientIdAccountNumber.has(inputState.Type.value)),
      // timespanInput('StartTime', 'none'),
      // timespanInput('EndTime', 'none'),
      // checkboxInput('UseLocalTime'),
      checkboxInput('SendRedundantResendRequests'),
      checkboxInput('ResetOnLogon'),
      checkboxInput('ResetOnLogout'),
      checkboxInput('ResetOnDisconnect'),
      checkboxInput('SslEnable'),
      checkboxInput('SslValidateCertificates').disabled(!execState.SslEnable),
      checkboxInput('SslCheckCertificateRevocation').disabled(!execState.SslEnable),
      textInput('SslCertificate').disabled(!execState.SslEnable).holder('optional'),
      passwordInput('SslCertificatePassword', type === 'add' ? 'setup' : 'change')
        .disabled(!execState.SslEnable)
        .holder('optional'),
      textInput('SslCaCertificate').disabled(!execState.SslEnable).holder('optional'),
      textInput('SslServerName').disabled(!execState.SslEnable).holder('optional'),
      sselectInput('SslProtocols', buildMultiselectOptionsFromArray(gateway?.SslProtocols ?? []))
        .optionZindex(false)
        .disabled(!execState.SslEnable)
        .textMessages(
          execState.SslProtocols.value === 'SSL 2.0' || execState.SslProtocols.value === 'SSL 3.0'
            ? 'Protocol is deprecated. Not all systems support it, the connection may not work!'
            : '',
        ),
      blankInput().skipWhen(!senderSubIdFieldSetFeedExec.has(inputState.Type.value)),
      textInput('Brand').skipWhen(!brandFieldSet.has(inputState.Type.value)),
      textInput('OnBehalfOfCompId').skipWhen(!onBehalfOfCompIdFieldSet.has(inputState.Type.value)),
      textInput('PartyId').skipWhen(!partyIdFieldSet.has(inputState.Type.value)),
      textInput('SenderSubId').skipWhen(!senderSubIdFieldSet.has(inputState.Type.value)),
      textInput('TargetSubID').skipWhen(!targetSubIDFieldSet.has(inputState.Type.value)),
      blankInput().skipWhen(!senderSubIdFieldSetFeedExec.has(inputState.Type.value)),

      textInput('MarketExecutionInstructions').skipWhen(!senderSubIdFieldSetFeedExec.has(inputState.Type.value)),
      textInput('LimitExecutionInstructions').skipWhen(!senderSubIdFieldSetFeedExec.has(inputState.Type.value)),
    ],
    execState,
    setExecState,
    'lp',
    execTouched,
    setExecTouched,
    execErrors,
  )
}

export const feedingSession = (
  feedingState: any,
  setFeedingState: any,
  feedingTouched: any,
  setFeedingTouched: any,
  feedingErrors: any,
  type: string,
  inputState: any,
  gateway: any,
  sendercompTargetcompId: any,
) => {
  const alreadyExist = () => {
    for (const item of sendercompTargetcompId) {
      if (
        item?.SenderCompId === feedingState.SenderCompId &&
        item?.TargetCompId === feedingState.TargetCompId &&
        feedingState.SenderCompId.length !== 0 &&
        feedingState.TargetCompId.length !== 0
      ) {
        feedingErrors.SenderCompId = true
        feedingErrors.TargetCompId = true
        return 'Аlready exists'
      }
    }

    if (feedingState.SenderCompId === feedingState.TargetCompId && feedingState.SenderCompId.length !== 0 && feedingState.TargetCompId.length !== 0) {
      feedingErrors.SenderCompId = true
      feedingErrors.TargetCompId = true
      return 'Incorrect value'
    }
    return ''
  }

  return buildControlsExtTwoPerLine(
    [
      textInput('SenderCompId').errorMessage(alreadyExist()),
      textInput('TargetCompId').errorMessage(alreadyExist()),
      textInput('BeginString'),
      textInput('Username'),
      textInput('Password'),
      textInput('ReconnectInterval'),
      textInput('FileStorePath'),
      textInput('FileLogPath'),
      checkboxInput('WriteLogs'),
      textInput('SocketConnectHost'),
      textInput('SocketConnectPort'),
      textInput('Account').skipWhen(inputState.Type.value !== 'CMC'),
      textInput('Brand').skipWhen(inputState.Type.value !== 'CMC'),
      // timespanInput('StartTime', 'none'),
      // timespanInput('EndTime', 'none'),
      // checkboxInput('UseLocalTime'),
      checkboxInput('ResetOnLogon'),
      checkboxInput('ResetOnLogout'),
      checkboxInput('ResetOnDisconnect'),
      checkboxInput('SslEnable'),
      checkboxInput('SslValidateCertificates').disabled(!feedingState.SslEnable),
      checkboxInput('SslCheckCertificateRevocation').disabled(!feedingState.SslEnable),
      textInput('SslCertificate').disabled(!feedingState.SslEnable).holder('optional'),
      passwordInput('SslCertificatePassword', type === 'add' ? 'setup' : 'change')
        .disabled(!feedingState.SslEnable)
        .holder('optional'),
      textInput('SslCaCertificate').disabled(!feedingState.SslEnable).holder('optional'),
      textInput('SslServerName').disabled(!feedingState.SslEnable).holder('optional'),
      sselectInput('SslProtocols', buildMultiselectOptionsFromArray(gateway?.SslProtocols ?? []))
        .optionZindex(true, 5232323)
        .disabled(!feedingState.SslEnable)
        .textMessages(
          feedingState.SslProtocols.value === 'SSL 2.0' || feedingState.SslProtocols.value === 'SSL 3.0'
            ? 'Protocol is deprecated. Not all systems support it, the connection may not work!'
            : '',
        ),

      blankInput(),
      textInput('QueryInterval').skipWhen(!queryIntervalFieldSet.has(inputState.Type.value)),
      textInput('SenderSubId').skipWhen(!senderSubIdFieldSet.has(inputState.Type.value)),
      textInput('TargetSubID').skipWhen(!targetSubIDFieldSet.has(inputState.Type.value)),
      textInput('ResubscribeOnRejectTimeout').skipWhen(!resubscribeOnRejectTimeoutFieldSet.has(inputState.Type.value)),
      sselectInput('IgnoreLogOptions', buildMultiselectOptionsFromArray(['Nothing', 'Quote', 'Other'])).optionZindex(true, 4232323),
      checkboxInput('QuoteTextProcessing').skipWhen(!quoteTextProcessingFieldSet.has(inputState.Type.value)),
    ],
    feedingState,
    setFeedingState,
    'lp',
    feedingTouched,
    setFeedingTouched,
    feedingErrors,
  )
}

export const laverate = (leverateState: any, setLeverateState: any, leverateTouched: any, setLeverateTouched: any, leverateErrors: any, inputState: any) => {
  return buildControlsExtTwoPerLine(
    [
      textInput('ApiKey'),
      textInput('ApiSecret'),
      textInput('LogPath').disabled(!leverateState.EnableFeederLogging),
      textInput('ReconnectTimeout'),
      checkboxInput('EnableFeederLogging'),
      textInput('WebServiceUrl').skipWhen(inputState.Type.value === 'Leverate'),
      textInput('WebServicePort')
        .skipWhen(inputState.Type.value === 'MorningStar')
        .skipWhen(inputState.Type.value === 'Leverate'),
    ],
    leverateState,
    setLeverateState,
    'leverate',
    leverateTouched,
    setLeverateTouched,
    leverateErrors,
  )
}

export const alpaca = (alpacaState: any, setAlpacaState: any, alpacaTouched: any, setAlpacaTouched: any, alpacaErrors: any) => {
  return buildControlsExtTwoPerLine(
    [textInput('ApiKey'), textInput('ApiSecret'), checkboxInput('Live')],
    alpacaState,
    setAlpacaState,
    'lp',
    alpacaTouched,
    setAlpacaTouched,
    alpacaErrors,
  )
}

export const synthetic = (syntheticState: any, setSyntheticState: any, syntheticTouched: any, setSyntheticTouched: any, syntheticErrors: any) => {
  return buildControlsExtTwoPerLine([textInput('QuoteRefreshTimeout')], syntheticState, setSyntheticState, 'lp', syntheticTouched, setSyntheticTouched, syntheticErrors)
}

export const hedgingPool = (hedgingBBookState: any, setHedgingBBookState: any, hedgingBBookTouched: any, setHedgingBBookTouched: any, errors: any, pool: any) => {
  return buildControlsExtTwoPerLine(
    [sselectInput('AggregationPool', buildMultiselectOptionsFromArray(pool), false).errorMessage('Incorrect value')],
    hedgingBBookState,
    setHedgingBBookState,
    'lp',
    hedgingBBookTouched,
    setHedgingBBookTouched,
    errors,
  )
}

export const LMAXCrypto = (LMAXCryptoOneState: any, setLMAXCryptoOneState: any, LMAXCryptoOneTouched: any, setLMAXCryptoOneTouched: any, LMAXCryptoOneErrors: any) => {
  return buildControlsExtTwoPerLine(
    [checkboxInput('Enabled'), textInput('CheckInterval'), textInput('PriceDocumentLink')],
    LMAXCryptoOneState,
    setLMAXCryptoOneState,
    'LMAXCrypto',
    LMAXCryptoOneTouched,
    setLMAXCryptoOneTouched,
    LMAXCryptoOneErrors,
  )
}

export const binanceInputsOne = (binanceState: any, setBinanceState: any, binanceTouched: any, setBinanceTouched: any, binanceErrors: any, inputState: any) => {
  return buildControlsExtTwoPerLine(
    [
      textInput('AllowedCancellationsForUser').skipWhen(
        inputState?.Type?.value === 'Huobi' || inputState?.Type?.value === 'Okx' || inputState?.Type?.value === 'AlpacaMarkets' || inputState?.Type?.value === 'OkxFutures',
      ),
      textInput('AllowedCancellationsForSession').skipWhen(
        inputState?.Type?.value === 'Huobi' || inputState?.Type?.value === 'Okx' || inputState?.Type?.value === 'AlpacaMarkets' || inputState?.Type?.value === 'OkxFutures',
      ),
      textInput('ReconnectTimeout').skipWhen(inputState?.Type?.value === 'AlpacaMarkets'),
      textInput('CancellationRatingResetTimeout').skipWhen(
        inputState?.Type?.value === 'Huobi' || inputState?.Type?.value === 'Okx' || inputState?.Type?.value === 'AlpacaMarkets' || inputState?.Type?.value === 'OkxFutures',
      ),
      textInput('LogPath').skipWhen(inputState?.Type?.value === 'AlpacaMarkets'),

      textInput('TasksLimit').skipWhen(
        inputState?.Type?.value === 'Huobi' || inputState?.Type?.value === 'Okx' || inputState?.Type?.value === 'AlpacaMarkets' || inputState?.Type?.value === 'OkxFutures',
      ),
      textInput('ReceiveWindow').skipWhen(
        inputState?.Type?.value === 'Huobi' || inputState?.Type?.value === 'Okx' || inputState?.Type?.value === 'AlpacaMarkets' || inputState?.Type?.value === 'OkxFutures',
      ),
      checkboxInput('ConfirmClosingOrdersUnderMinNotional')
        .skipWhen(inputState?.Type?.value === 'BinanceStock')
        .skipWhen(inputState.Type.value === 'HitBTCWebSocket')
        .skipWhen(
          inputState?.Type?.value === 'Huobi' || inputState?.Type?.value === 'Okx' || inputState?.Type?.value === 'AlpacaMarkets' || inputState?.Type?.value === 'OkxFutures',
        ),
      checkboxInput('EnableFeederLogging').skipWhen(inputState?.Type?.value === 'AlpacaMarkets'),

      checkboxInput('UseDefaultServers'),
      textInput('RestAddress').skipWhen(binanceState?.UseDefaultServers),
      textInput('SocketStreamAddress')
        .skipWhen(binanceState?.UseDefaultServers)
        .skipWhen(inputState?.Type?.value === 'BinanceFuturesCoin')
        .skipWhen(inputState?.Type?.value === 'BinanceFutures'),
      textInput('SocketApiAddress').skipWhen(binanceState?.UseDefaultServers),
    ],
    binanceState,
    setBinanceState,
    'lp',
    binanceTouched,
    setBinanceTouched,
    binanceErrors,
  )
}

export const binanceInputsTwo = (
  binanceState: any,
  setBinanceState: any,
  binanceTouched: any,
  setBinanceTouched: any,
  binanceErrors: any,
  FuturesAllowedValues: any,
  StockAllowedValues: any,
  inputState: any,
  type: any,
) => {
  return buildControlsExtTwoPerLine(
    [
      textInput('ApiKey'),
      passwordInput('ApiSecret').setMode(type),
      sselectInput(
        'DepthLevels',
        binanceType(inputState) === 'BinanceFutures'
          ? buildMultiselectOptionsFromArray(FuturesAllowedValues?.DepthLevels.map(String))
          : buildMultiselectOptionsFromArray(StockAllowedValues?.DepthLevels.map(String)),
        false,
      )
        .skipWhen(inputState?.Type?.value === 'Huobi' || inputState?.Type?.value === 'Okx' || inputState?.Type?.value === 'OkxFutures')
        .optionZindex(false),
      sselectInput(
        'QuotesUpdateInterval',
        binanceType(inputState) === 'BinanceFutures'
          ? buildMultiselectOptionsFromArray(FuturesAllowedValues?.QuotesUpdateIntervals.map(String))
          : buildMultiselectOptionsFromArray(StockAllowedValues?.QuotesUpdateIntervals.map(String)),
        false,
      )
        .optionZindex(false)
        .skipWhen(inputState?.Type?.value === 'Huobi' || inputState?.Type?.value === 'Okx' || inputState?.Type?.value === 'OkxFutures'),

      textInput('Leverage').skipWhen(!targetBinanceFuturesFieldSet.has(inputState?.Type?.value)),
      checkboxInput('StreamLastPrices').skipWhen(inputState?.Type?.value === 'Huobi' || inputState?.Type?.value === 'Okx' || inputState?.Type?.value === 'OkxFutures'),
      sselectInput('PositionMode', [
        { value: 'LongShort', label: 'Long/Short' },
        { value: 'Net', label: 'Net' },
      ]).skipWhen(inputState?.Type?.value !== 'OkxFutures'),
      sselectInput('TradeMode', buildMultiselectOptionsFromArray(['Cross', 'Isolated']))
        .optionZindex(true, 1)
        .skipWhen(inputState?.Type?.value !== 'OkxFutures'),
      textInput('PassPhrase').skipWhen(!OkxLive.has(inputState?.Type?.value)),
      textInput('BrokerId').skipWhen(!OkxLive.has(inputState?.Type?.value)),
      checkboxInput('Live').skipWhen(!OkxLive.has(inputState?.Type?.value)),
    ],
    binanceState,
    setBinanceState,
    inputState?.Type?.value === 'Huobi' ? 'huobi' : 'lp',
    binanceTouched,
    setBinanceTouched,
    binanceErrors,
  )
}

export const interactiveBrokersInputs = (
  interactiveBrokersState: any,
  setInteractiveBrokersState: any,
  interactiveBrokersTouched: any,
  setInteractiveBrokersTouched: any,
  interactiveBrokersErrors: any,
  type: any,
) => {
  return buildControlsExtTwoPerLine(
    [textInput('WebServiceUrl'), textInput('WebServicePort'), textInput('DepthLevels'), textInput('ReconnectTimeout'), textInput('LogPath'), checkboxInput('EnableFeederLogging')],
    interactiveBrokersState,
    setInteractiveBrokersState,
    'lp',
    interactiveBrokersTouched,
    setInteractiveBrokersTouched,
    interactiveBrokersErrors,
  )
}
